import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import FilterListIcon from "@material-ui/icons/FilterList";
// import PhoneIcon from '@material-ui/icons/Phone';
// import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import RefreshIcon from "@material-ui/icons/Refresh";

const NavigationDrawer = props => {
  let additionalOptions = [];
  let updateAvailableOption = null;
  if (props.drawerAdditionalOptions) {
    additionalOptions = [
      <ListItem key={3} button>
        <ListItemIcon>
          <FilterListIcon />
        </ListItemIcon>
        <ListItemText primary="Show Completed Addresses" />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={props.toggleWalkedAddresses}
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            checked={props.showWalkedAddresses}
            inputProps={{ "aria-labelledby": "switch-list-label-showWalked" }}
          />
        </ListItemSecondaryAction>
      </ListItem>
      // <ListItem key={4} button>
      //     <ListItemIcon>
      //         <PhoneIcon/>
      //     </ListItemIcon>
      //     <ListItemText primary="Show Phone Numbers?"/>
      //     <ListItemSecondaryAction>
      //         <Switch
      //             edge="end"
      //             onChange={() => props.handleSwitchToggle('showPhoneNumbers')}
      //             onClick={event => event.stopPropagation()}
      //             onFocus={event => event.stopPropagation()}
      //             checked={props.showPhoneNumbers}
      //             inputProps={{'aria-labelledby': 'switch-list-label-showPhoneNumbers'}}
      //         />
      //     </ListItemSecondaryAction>
      // </ListItem>,
      // <ListItem key={5} button>
      //     <ListItemIcon>
      //         <HomeIcon/>
      //     </ListItemIcon>
      //     <ListItemText primary="Show All Voters at House?"/>
      //     <ListItemSecondaryAction>
      //         <Switch
      //             edge="end"
      //             onChange={() => props.handleSwitchToggle('showAllVoters')}
      //             onClick={event => event.stopPropagation()}
      //             onFocus={event => event.stopPropagation()}
      //             checked={props.showAllVoters}
      //             inputProps={{'aria-labelledby': 'switch-list-label-showAllVoters'}}
      //         />
      //     </ListItemSecondaryAction>
      // </ListItem>
    ];
  }

  // if (props.updateAvailable) {
  updateAvailableOption = (
    <ListItem
      button
      onClick={() => {
        window.location.reload();
      }}
    >
      <ListItemIcon>
        <RefreshIcon />
      </ListItemIcon>
      <ListItemText primary={"Load Newest Version"} />
    </ListItem>
  );
  // }

  const appVersion = process.env.REACT_APP_VERSION
    ? process.env.REACT_APP_VERSION
    : "-dev";

  return (
    <Drawer
      anchor="top"
      open={props.showDrawer}
      onClose={props.toggleDrawer(false)}
    >
      <div
        role="presentation"
        onClick={props.toggleDrawer(false)}
        onKeyDown={props.toggleDrawer(false)}
      >
        <List>
          <ListItem button onClick={props.signOut}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
          <ListItem button onClick={props.changePacket}>
            <ListItemIcon>
              <AssignmentTurnedInIcon />
            </ListItemIcon>
            <ListItemText primary="Change Packet" />
          </ListItem>
          {additionalOptions.map(item => item)}
          <ListItem divider={true} />
          {updateAvailableOption}
          <ListItem disabled={true}>
            <ListItemText
              secondary={"v" + appVersion}
              secondaryTypographyProps={{ variant: "caption", align: "center" }}
            />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default NavigationDrawer;
