import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StarIcon from "@material-ui/icons/Star";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Checkbox from "@material-ui/core/Checkbox";
import SyncIcon from "@material-ui/icons/Sync";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import db from "../../../db";
import StreetItem from "../StreetItem/StreetItem";
import PacketToolbar from "../PacketToolbar/PacketToolbar";
import { getAppBarHeight } from "../../../utils.js";
import * as updater from "../../../updater.js";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 2),
    margin: theme.spacing(1, 0)
  },
  bold: {
    fontWeight: "bold"
  },
  wrapIcon: {
    padding: theme.spacing(1, 1, 1, 0)
  },
  details: {
    padding: theme.spacing(1, 2, 2)
  },
  actions: {
    padding: theme.spacing(1, 0, 0)
  },
  iconStyle: {
    padding: theme.spacing(0, 0.5, 0, 0)
  },
  panelSummary: {
    padding: theme.spacing(0, 1.5)
  },
  green: {
    backgroundColor: "#dff0d8"
  }
}));

const PacketListing = props => {
  const apiUrl = process.env.REACT_APP_WALK_APP_API_URL;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [addresses, setAddresses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [geoCodes, setGeoCodes] = useState([]);
  const [streetName, setStreetName] = useState(null);
  const [, setSyncQueue] = useState([]);
  const [appBarHeight, setAppBarHeight] = useState(
    getAppBarHeight(window.innerWidth)
  );  

  const {
    packetCode,
    isMap,
    selectedStreet,
    mapAddress,
    packet, 
    selectStreet,
    markedCompleted,    
    showWalkedAddresses,
    showPhoneNumbers,
    showAllVoters,
    isSyncing,
    dbRefreshCount,
    mapIds,
    setErrorMsg
  } = props;

  const handlePanelSelect = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const eventId = updater.subscribeEvent(event => {
      switch(event.type) {
        case updater.EV_ADDRESS_PROCESSED:          
          const index = addresses.findIndex(x => x.addressId === event.data.id);
          if (index >= 0) {            
            const newAddresses = JSON.parse(JSON.stringify(addresses));
            if (event.data.updateProcessing) {
              newAddresses[index].isProcessing = false;
            }
            const groupsData = event.data.updateProcessingGroups;
            if (groupsData) {
              const record = newAddresses[index].records.find(
                x => x.recordId === groupsData.recordId && x.cvId === groupsData.cvId
              );                           
              const { [groupsData.groupId]:_, ...others } = record.processingGroups;
              record.processingGroups = others;;
            }
            setAddresses(newAddresses);
          }
          break;   
          
        case updater.EV_ERROR:          
          setErrorMsg(event.data.error);
          break;
        default:
      }
    });

    return () => {      
      updater.unsubscribeEvent(eventId);
    };  
  }, [
    addresses,
    setErrorMsg
  ]);

  useEffect(() => {
    const handleResize = () => {
      setAppBarHeight(getAppBarHeight(window.innerWidth));
    };
    const fetchData = () => {
      let currStreetName = null;
      if (!!!isMap && selectedStreet) {
        currStreetName = selectedStreet.toUpperCase();
      }
      if (!!isMap && mapAddress) {
        currStreetName = mapAddress;
      }

      if (currStreetName === null) {
        selectStreet(null);
      } else {
        setStreetName(currStreetName);
        const addressQuery = isMap
          ? db
              .table("addresses")
              .where("addressId")
              .anyOf(Object.keys(mapIds).map(id => parseInt(id)))
          : db
              .table("addresses")
              .where("streetName")
              .equals(currStreetName);
        Promise.all([
          addressQuery.toArray().then(allAddr => {
            const allAddresses = allAddr.slice(0);
            allAddresses.sort((a, b) => {
              const aa = a.address.split(" ");
              const bb = b.address.split(" ");
              const res = parseInt(aa[0]) - parseInt(bb[0]);
              if (res !== 0) {
                return res;
              } else {
                const lasta = aa.length - 1;
                const lastb = bb.length - 1;
                return parseInt(aa[lasta]) - parseInt(bb[lastb]);
              }
            });
            if (allAddresses.length === 1) {
              setExpanded("panel" + allAddresses[0].addressId);
            }
            setAddresses(allAddresses);
          }),
          db
            .table("groups")
            .where("packetCode")
            .equals(packetCode)
            .toArray()
            .then(groups => {
              setGroups(groups);
            }),
          db
            .table("geoCodes")
            .toArray()
            .then(allGeoCodes => {
              setGeoCodes(allGeoCodes);
            }),
          db
            .table("syncQueue")
            .toArray()
            .then(queue => {
              setSyncQueue(queue);
            })
        ]);
      }
    };

    window.addEventListener("resize", handleResize);
    if (packetCode !== null) {
      fetchData();
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [
    isMap,
    mapAddress,
    packetCode,
    selectStreet,
    selectedStreet,
    mapIds,
    dbRefreshCount
  ]);

  const syncGeoCodes = async addressId => {
    const currGeoCodes = JSON.parse(JSON.stringify(geoCodes));
    const index = geoCodes.findIndex(
      x => Object.keys(x.ids).findIndex(xx => xx === addressId.toString()) >= 0
    );
    if (index >= 0) {
      let allCompleted = false;
      currGeoCodes[index].ids[addressId.toString()] =
        currGeoCodes[index].ids[addressId.toString()] === 2 ? 3 : 2;
      if (Object.values(currGeoCodes[index].ids).findIndex(x => x === 2) < 0) {
        allCompleted = true;
      }

      currGeoCodes[index].status = allCompleted ? 3 : 2;

      const keys = await db
        .table("geoCodes")
        .toCollection()
        .primaryKeys();

      db.table("geoCodes").update(keys[index], currGeoCodes[index]);
      await props.createStreetListing();
      setGeoCodes(currGeoCodes);
    }
  };

  const statusChange = (addressId, currStatus, isCall = false) => {
    const newStatus = currStatus > 2 ? 2 : 3;
    const newAddresses = JSON.parse(JSON.stringify(addresses));
    const index = addresses.findIndex(x => x.addressId === addressId);    
    newAddresses[index].status = newStatus;
    newAddresses[index].isProcessing = true;
    setAddresses(newAddresses);    

    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("authToken")
      }
    };
    const url = apiUrl + "walk/statusChange";

    const now = new Date();
    const postData = {
      typeId: packet.alType,
      projectId: packet.alId,
      addressId: addressId,
      status: newStatus,
      timestamp: now.getTime(),
      currentTime: now.getTime()
    };

    updater.updateStatus(url, config, postData);

    syncGeoCodes(addressId);

    if (isMap) {
      markedCompleted(addressId);
    }
  };  

  const groupChange = (addressId, recordId, groupId, cvId, currStatus) => {
    const newAddresses = JSON.parse(JSON.stringify(addresses));
    const addressIndex = newAddresses.findIndex(x => x.addressId === addressId);
    const address = newAddresses[addressIndex];
    const recordIndex = address.records.findIndex(
      x => x.recordId === recordId && x.cvId === cvId
    );
    const record = address.records[recordIndex];
    const groupIndex = record.groups.indexOf(groupId);    
    const now = new Date();
    if (record.groups.length === 0 && currStatus !== 1 && address.status <= 2) {
      // If first group being selected, mark record completed      
      statusChange(addressId, address.status, true);
      newAddresses[addressIndex].status = 3;
    }
    const newRecord = newAddresses[addressIndex].records[recordIndex];
    const newGroups = newRecord.groups;  
    if (currStatus === 1) {
      newGroups.splice(
        groupIndex,
        1
      );      
    } else {
      newGroups.push(groupId);      
    }
    const procGroups = newRecord.processingGroups || {};
    newRecord.processingGroups = { ...procGroups, [groupId]: true };
    setAddresses(newAddresses);    

    const postData = {
      recordId: record.recordId,
      recordType: record.recordType,
      cvId: record.cvId,
      groupId: groupId,
      addressId: addressId,
      action: currStatus === 1 ? 0 : 1,
      timestamp: now.getTime(),
      currentTime: now.getTime()
    };
    const config = {
      headers: { Authorization: "Bearer " + localStorage.getItem("authToken") }
    };
    const url = apiUrl + "walk/groupChange";
    
    updater.updateGroups(url, config, postData);
  }

  const addressList = addresses.map(address => {
    if (showWalkedAddresses === false && address.hide) {
      return null;
    }
    const bgClass = address.status > 2 ? classes.green : null;    
    return (
      <ExpansionPanel
        expanded={expanded === "panel" + address.addressId}
        onChange={handlePanelSelect("panel" + address.addressId)}
        className={bgClass}
        key={address.addressId}
      >
        <ExpansionPanelSummary
          className={classes.panelSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <FormControlLabel
              checked={address.status > 2}
              aria-label="Address"
              onChange={event => {
                event.stopPropagation();
                statusChange(address.addressId, address.status);
              }}
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={<Checkbox />}
              label={address.address}
            />
            <span
              style={
                // !!syncQueue.find(
                //   ({ type, data }) =>
                //     type === "status" && data.addressId === address.addressId
                // )
                address.isProcessing
                  ? isSyncing
                    ? {
                        transitionProperty: "transform",
                        transitionDuration: "1s",
                        animationName: "rotate",
                        animationDuration: "2s",
                        animationIterationCount: "infinite",
                        animationTimingFunction: "linear",
                        width: 24,
                        height: 24,
                        color: "goldenrod"
                        // color: address.status === 3 ? "goldenrod" : ""
                      }
                    : {
                        transitionProperty: "transform",
                        transitionDuration: "1s",
                        width: 24,
                        height: 24,
                        color: "goldenrod"
                        // color: address.status === 3 ? "goldenrod" : ""
                      }
                  : {
                      display: "none"
                    }
              }
            >
              <SyncIcon />
            </span>
          </div>
        </ExpansionPanelSummary>
        {address.records.map(record => {
          let phoneRecord = "";
          if (record.phone !== "" && showPhoneNumbers) {
            phoneRecord = (
              <span>
                <a href={"tel: " + record.phone.replace(/\D/g, "")}>
                  {record.phone}
                </a>
                <Typography variant="caption">
                  {record.phoneSourceCodes}
                </Typography>
              </span>
            );
          }
          if (!showAllVoters && record.bold !== 1) {
            return null;
          }
          return (
            <ExpansionPanelDetails
              key={record.recordId}
              className={classes.details}
            >
              <div>
                <Typography variant="subtitle1">
                  <span
                    className="icon-vote"
                    style={{ color: record.colorBox }}
                  >
                    &nbsp;
                  </span>
                  {record.bold === 1 ? (
                    <span className={classes.bold}>{record.name}</span>
                  ) : (
                    record.name
                  )}
                  {phoneRecord}
                </Typography>
                {groups.map(group => {
                  const isSynced = record.processingGroups ? !!record.processingGroups[group.id] : false;                  

                  let icon = isSynced ? (
                    <span
                      style={
                        isSyncing
                          ? {
                              transitionProperty: "transform",
                              transitionDuration: "1s",
                              animationName: "rotate",
                              animationDuration: "2s",
                              animationIterationCount: "infinite",
                              animationTimingFunction: "linear",
                              width: 24,
                              height: 24
                            }
                          : {
                              transitionProperty: "transform",
                              transitionDuration: "1s",
                              width: 24,
                              height: 24
                            }
                      }
                    >
                      <SyncIcon />
                    </span>
                  ) : (
                    <StarBorderIcon className={classes.iconStyle} />
                  );
                  let status = 0;
                  if (record.groups.indexOf(group.id) >= 0) {
                    status = 1;
                    icon = isSynced ? (
                      <span
                        style={
                          isSyncing
                            ? {
                                transitionProperty: "transform",
                                transitionDuration: "1s",
                                animationName: "rotate",
                                animationDuration: "2s",
                                animationIterationCount: "infinite",
                                animationTimingFunction: "linear",
                                width: 24,
                                height: 24,
                                color: "goldenrod"
                              }
                            : {
                                transitionProperty: "transform",
                                transitionDuration: "1s",
                                width: 24,
                                height: 24,
                                color: "goldenrod"
                              }
                        }
                      >
                        <SyncIcon />
                      </span>
                    ) : (
                      <StarIcon
                        className={classes.iconStyle}
                        style={{ color: "goldenrod" }}
                      />
                    );
                  }
                  return (
                    <Button
                      key={
                        address.addressId +
                        " " +
                        record.recordId +
                        " " +
                        group.id +
                        "  " +
                        record.cvId
                      }
                      className={classes.wrapIcon}
                      onClick={() =>
                        groupChange(
                          address.addressId,
                          record.recordId,
                          group.id,
                          record.cvId,
                          status
                        )
                      }
                    >
                      {icon} {group.nameShort}
                    </Button>
                  );
                })}
              </div>
            </ExpansionPanelDetails>
          );
        })}
      </ExpansionPanel>
    );
  });

  let completeTotal = 0;
  let total = 0;
  addresses.forEach(address => {
    if (address.status > 2) {
      completeTotal++;
    }
    total++;
  });
  const currentStreet = {
    name: streetName,
    complete: completeTotal,
    total: total
  };

  return (
    <Container maxWidth="sm">
      {!isMap && (
        <div>
          <StreetItem
            street={currentStreet}
            showBack={true}
            selectStreet={props.selectStreet}
          />
          <PacketToolbar {...props} showChild={true} />
        </div>
      )}
      <div
        style={{
          height: `${window.innerHeight - 160 - appBarHeight}px`,
          overflow: "auto"
        }}
      >
        {addressList}
      </div>
    </Container>
  );
};

export default PacketListing;
