import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Paper from "@material-ui/core/Paper";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(theme => ({
    list: {
        padding: theme.spacing(0, 0, 1),
    },
    menuItemIcon: {
        minWidth: '36px'
    }
}));

const ColorLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: '#f0ad4e',
    },
    barColorPrimary: {
        backgroundColor: '#5cb85c',
    },
})(LinearProgress);

const StreetItem = (props) => {
    const classes = useStyles();

    let icon = <ChevronRightIcon/>;
    if (props.showBack) {
        icon = <ChevronLeftIcon/>;
    }
    if (!props.street) {
        return null;
    }
    return <Paper key={props.street.name}>
        <ListItem button onClick={() => {
            if (props.showBack) {
                props.selectStreet(null);
            } else {
                props.selectStreet(props.street);
            }
        }}>
            <ListItemIcon className={classes.menuItemIcon}>
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={
                    <React.Fragment>
                        {props.street.name}
                        <ColorLinearProgress
                            variant="determinate"
                            value={(props.street.complete / props.street.total) * 100}/>
                        {props.street.complete}/{props.street.total} completed
                    </React.Fragment>
                }/>
        </ListItem>
    </Paper>;
};

export default StreetItem;