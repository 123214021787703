import React, {useEffect, useRef} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    errorSnackbar: {
        backgroundColor: '#FFAB00'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '55%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
});

const PacketForm = (props) => {
    const {classes} = props;
    const handleFocus = (e) => e.target.select();
    const packetInput = useRef();

    useEffect(() => {
        packetInput.current && packetInput.current.focus();
    }, []);

    const snackBar = <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        open={props.showPacketError}
        autoHideDuration={10000}
        onClose={props.handlePacketErrorClose}
        ContentProps={{
            classes: {
                root: classes.errorSnackbar
            },
            'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Could not find packet. Please try again.</span>}
        action={[
            <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={props.handlePacketErrorClose}>
                <CloseIcon/>
            </IconButton>,
        ]}
    />;

    return (
      <Container maxWidth="sm">
        <div className={classes.paper}>
          <form
            className={classes.form}
            onSubmit={props.formSubmitHandler}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              type="tel"
              required
              fullWidth
              id="packet-id"
              label="Packet ID"
              name="packet_id"
              autoFocus
              onFocus={handleFocus}
              onChange={props.inputChangeHandler}
              value={props.packetValue}
              inputRef={packetInput}
            />
            <div className={classes.wrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={props.loading}
              >
                Next
              </Button>
              {props.loading && (
                <CircularProgress
                  size={22}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </form>
          {snackBar}
        </div>
        {props.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#333",
              opacity: "0.7",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column"
              }}
            >
              <CircularProgress />
              <div style={{ color: "#fff", lineHeight: "50px" }}>
                {window.loadingText}
              </div>
            </div>
          </div>
        )}
      </Container>
    );
};

export default withStyles(styles)(PacketForm);