import React from "react";
import {withStyles} from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {green} from '@material-ui/core/colors';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import LinearProgress from "@material-ui/core/LinearProgress";
import PacketToolbar from "../PacketToolbar/PacketToolbar";

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    tabs: {
        marginBottom: theme.spacing(1.5)
    },
    packetHeader: {
        backgroundColor: '#fff',
    },
    packetHeaderContent: {
        padding: theme.spacing(1.5) + 'px !important'
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    packetTitle: {
        flexGrow: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    packetCode: {
        color: '#767676',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
});

const ColorLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: '#f0ad4e',
    },
    barColorPrimary: {
        backgroundColor: '#5cb85c',
    },
})(LinearProgress);

const LightTooltip = withStyles(theme => ({
  tooltip: {
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    margin: '8px 0',
  }
}))(Tooltip);

const PacketHeader = (props) => {
    const {classes} = props;
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };

    const handleTooltipOpen = () => {
      setOpen(true);
    };

    let packetCount = null;
    if (props.packet && Object.keys(props.packet).length > 0) {
        packetCount = props.packet.countComplete + ' of ' + props.packet.countTotal + ' complete';
    }

    return (
      <div style={{ height: "200px", position: "relative" }}>
        <Card className={classes.packetHeader}>
          <CardContent className={classes.packetHeaderContent}>
            <Typography
              variant="h6"
              component="h6"
              className={classes.packetTitle}
            >
              {props.packet.alpName}
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              className={classes.packetCode}
            >
              ID: {props.packet.packetString}
            </Typography>
            <ColorLinearProgress
              variant="determinate"
              value={
                (props.packet.countComplete / props.packet.countTotal) * 100
              }
            />
            {packetCount}
          </CardContent>
          { props.packet.alDescription && props.packet.alDescription !== "" && <ClickAwayListener onClickAway={handleTooltipClose}>
            <LightTooltip
              PopperProps={{
                disablePortal: true
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={props.packet.alDescription}
              arrow
              placement="bottom"
            >
              <IconButton
                edge="start"
                aria-label="info"
                onClick={handleTooltipOpen}
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "12px",
                  padding: 0
                }}
              >
                <InfoIcon />
              </IconButton>
            </LightTooltip>
          </ClickAwayListener> }
        </Card>
        <PacketToolbar {...props} showChild={false} />
      </div>
    );
};

export default withStyles(styles)(PacketHeader);