import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow
} from "@react-google-maps/api";
import React, { useState, useEffect } from "react";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import SyncIcon from "@material-ui/icons/Sync";
import { withStyles } from "@material-ui/styles";

import { getAppBarHeight } from "../../../utils.js";

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -3,
    top: -5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}))(Badge);

const MapComponent = props => {
  const [myMap, setMyMap] = useState(null);

  const [selectedGeoCode, setSelectedGeoCode] = useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [markerMap, setMarkerMap] = useState({});
  const [currentPosition, setCurrentPosition] = useState(null);

  const [appBarHeight, setAppBarHeight] = useState(
    getAppBarHeight(window.innerWidth)
  );

  useEffect(() => {
    const handleResize = () => {
      setAppBarHeight(getAppBarHeight(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let markers = null;

  const colorMap = {
    0: "pink",
    1: "blue",
    2: "orange",
    3: "green",
    4: "red",
    5: "brown",
    6: "purple",
    7: "yellow",
    8: "darkgreen",
    9: "grey"
  };

  const createMapOptions = {
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    scaleControl: false
  };

  const markerLoadHandler = (marker, geoCode) => {
    return setMarkerMap(prevState => {
      return { ...prevState, [geoCode.lat + ", " + geoCode.lon]: marker };
    });
  };

  const handleClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
        let bounds = new window.google.maps.LatLngBounds();
        props.geoCodes.forEach(geoCode => {
          bounds.extend({ lat: geoCode.lat, lng: geoCode.lon });
        });
        bounds.extend({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
        myMap.fitBounds(bounds);
      });
    } else {
      alert("Geoloaction is not supported by your browser");
    }
  };

  const handleMarkerClick = geoCode => {
    setSelectedGeoCode(geoCode);
    if (infoWindowOpen) {
      setInfoWindowOpen(false);
    } else {
      setInfoWindowOpen(true);
    }
  };

  const setMap = m => {
    if (m) {
      setMyMap(m);
      let bounds = new window.google.maps.LatLngBounds();
      props.geoCodes.map(geoCode => {
        bounds.extend({ lat: geoCode.lat, lng: geoCode.lon });
        return geoCode.id;
      });
      m.fitBounds(bounds);
      let button = document.getElementById("your_location_img");
      let controlDiv = document.createElement("div");
      controlDiv.appendChild(button);
      controlDiv.index = 1;
      m.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
        controlDiv
      );

      let syncbutton = document.getElementById("sync_button");
      let syncDiv = document.createElement("div");
      syncDiv.appendChild(syncbutton);
      syncDiv.index = 1;
      m.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(syncDiv);
    }
  };

  if (props.geoCodes.length > 0) {
    markers = props.geoCodes.map((geoCode, index) => {
      const markerIcon = {
        url:
          "/assets/markers/marker_" +
          colorMap[geoCode.status < 3 ? props.colorId : 9] +
          ".png",
        labelOrigin: { x: 9, y: 11 }
      };
      if (!props.showWalkedAddresses && geoCode.status >= 3) {
        return null;
      }
      return (
        <Marker
          key={`marker_${index}`}
          position={{ lat: geoCode.lat, lng: geoCode.lon }}
          icon={markerIcon}
          onClick={() => handleMarkerClick(geoCode)}
          onLoad={marker => markerLoadHandler(marker, geoCode)}
          label={
            Object.keys(geoCode.ids).length > 1
              ? Object.keys(geoCode.ids).length.toString()
              : ""
          }
          zIndex={index}
        />
      );
    });
  }

  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
    >
      <GoogleMap
        onLoad={setMap}
        onUnmount={map => {
          map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].pop();
        }}
        mapContainerStyle={{
          height: `${window.innerHeight -
            appBarHeight -
            (props.isDisconnected ? 36 : 0)}px`,
          width: `100%`
        }}
        defalutZoom={8}
        options={createMapOptions}
      >
        {markers}
        {infoWindowOpen && selectedGeoCode && (
          <InfoWindow
            anchor={markerMap[selectedGeoCode.lat + ", " + selectedGeoCode.lon]}
            onCloseClick={() => setInfoWindowOpen(false)}
          >
            <Button onClick={() => props.selectGeoCode(selectedGeoCode)}>
              {selectedGeoCode.address}
            </Button>
          </InfoWindow>
        )}
        {currentPosition && (
          <Marker
            icon="/assets/markers/hereiam.png"
            position={currentPosition}
            zIndex={999999}
          />
        )}
        <button
          onClick={handleClick}
          style={{
            display: "flex",
            backgroundColor: "rgb(255, 255, 255)",
            border: "none",
            outline: "none",
            width: "40px",
            height: "40px",
            borderRadius: "2px",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px",
            cursor: "pointer",
            marginRight: "10px",
            padding: "0px"
          }}
          id="your_location_img"
        >
          <div
            style={{
              margin: "auto",
              width: "18px",
              height: "18px",
              backgroundImage:
                "url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)",
              backgroundSize: "180px 18px",
              backgroundPosition: "0px 0px",
              backgroundRepeat: "no-repeat"
            }}
          ></div>
        </button>

        <button
          onClick={() => props.syncAndFetch()}
          style={{
            display: "flex",
            backgroundColor: "rgb(255, 255, 255)",
            border: "none",
            outline: "none",
            width: "40px",
            height: "40px",
            borderRadius: "2px",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px",
            cursor: "pointer",
            marginRight: "10px",
            marginTop: "10px",
            padding: "0px",
            justifyContent: "center",
            alignItems: "center"
          }}
          id="sync_button"
        >
          <StyledBadge badgeContent={props.syncCount} color="primary">
            <span
              style={
                props.isSyncing
                  ? {
                      transitionProperty: "transform",
                      transitionDuration: "1s",
                      animationName: "rotate",
                      animationDuration: "2s",
                      animationIterationCount: "infinite",
                      animationTimingFunction: "linear"
                    }
                  : {
                      transitionProperty: "transform",
                      transitionDuration: "1s"
                    }
              }
            >
              <SyncIcon />
            </span>
          </StyledBadge>
        </button>
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
