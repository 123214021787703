import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import { BrowserRouter, Route } from "react-router-dom";

import Login from "../components/Login/Login";
import Packet from "../components/Packet/Packet";
import * as serviceWorker from "../serviceWorker";

const styles = theme => ({
  "@global": {
    body: {
      backgroundColor: "#f5f5f5"
    }
  },
  logo: {
    marginRight: "8px"
  },
  title: {
    flexGrow: 1,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  disconnected: {
    padding: theme.spacing(1, 1),
    margin: theme.spacing(0, 0, 1),
    backgroundColor: "#ffc107",
    textAlign: "center"
  },
  customBadge: {
    backgroundColor: "#ffc107"
  }
});

class App extends Component {
  state = {
    showDrawer: false,
    isLoggedIn: false,
    title: "weWalk",
    isDisconnected: false,
    updateAvailable: false,
    waitingServiceWorker: null
    // showBackButton: false,
  };

  toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ showDrawer: open });
  };

  setLoggedIn = (status, title = "weWalk") => {
    this.setState({ isLoggedIn: status, title: title });
  };

  setDisconnected = status => {
    this.setState({ isDisconnected: status });
  };

  refreshApp = () => {
    if (this.state.waitingServiceWorker) {
      this.state.waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });

      this.state.waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  };

  componentDidMount() {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({
      onUpdate: registration => {
        this.setState({
          updateAvailable: true,
          waitingServiceWorker: registration.waiting
        });
      }
    });

    const authToken = localStorage.getItem("authToken");
    const campaignName = localStorage.getItem("campaignName");
    if (authToken !== null) {
      this.setState({
        isLoggedIn: true,
        title: campaignName
      });
    }
  }

  render() {
    const { classes } = this.props;
    let backOrLogo;
    let menuIcon = null;
    let disconnected = null;

    if (this.state.isDisconnected) {
      disconnected = (
        <Paper className={classes.disconnected}>Internet connection lost</Paper>
      );
    }

    if (this.state.isLoggedIn) {
      menuIcon = (
        <IconButton
          onClick={this.toggleDrawer(true)}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <Badge
            classes={{ badge: classes.customBadge }}
            variant="dot"
            invisible={!this.state.updateAvailable}
          >
            <MenuIcon />
          </Badge>
        </IconButton>
      );
    }

    // if (this.state.showBackButton) {
    //   backOrLogo = (
    //     <IconButton
    //       onClick={() => window.history.go(-1)}
    //       edge="start"
    //       className={classes.menuButton}
    //       color="inherit"
    //       aria-label="menu"
    //     >
    //       <ArrowBackIcon />
    //     </IconButton>
    //   );
    // } else {
    backOrLogo = (
      <img src="/assets/qw.png" alt="weWalk" className={classes.logo} />
    );
    // }
    const appVersion = process.env.REACT_APP_VERSION
      ? process.env.REACT_APP_VERSION
      : "-dev";
    return (
      <>
        <AppBar position="relative">
          <Toolbar>
            {backOrLogo}
            <Typography
              variant="h6"
              className={classes.title}
              style={
                window.location.pathname === "/"
                  ? {
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between"
                    }
                  : {}
              }
            >
              {this.state.title}
              {window.location.pathname === "/" && (
                <span style={{ fontSize: "14px" }}>{appVersion}</span>
              )}
            </Typography>
            {menuIcon}
          </Toolbar>
        </AppBar>
        {disconnected}
        <>
          <BrowserRouter>
            <Route
              path="/"
              exact
              render={props => {
                return <Login {...props} setLoggedIn={this.setLoggedIn} />;
              }}
            />
            <Route
              path="/packet"
              render={props => {
                return (
                  <Packet
                    {...props}
                    toggleDrawer={this.toggleDrawer.bind(this)}
                    // setShowBackButton={this.setShowBackButton.bind(this)}
                    setLoggedIn={this.setLoggedIn.bind(this)}
                    isDisconnected={this.state.isDisconnected}
                    setDisconnected={this.setDisconnected.bind(this)}
                    showDrawer={this.state.showDrawer}
                    updateAvailable={this.state.updateAvailable}
                    refreshApp={this.refreshApp.bind(this)}
                  />
                );
              }}
            />
          </BrowserRouter>
        </>
      </>
    );
  }
}

export default withStyles(styles)(App);
