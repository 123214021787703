import axios from "axios";

import db from "./db";

export const TYPE_STATUS = 'address';
export const TYPE_GROUP = 'group';
export const DB_UPDATER_QUEUE = 'updaterQueue';
const DB_ADDRESSES = 'addresses';

export const EV_ADDRESS_PROCESSED = 'evAddressProcessed';
export const EV_TASKS_COUNT = 'evTasksCount';
export const EV_SYNCING = 'evSyncing';
export const EV_FETCH_START = 'evFetchStart';
export const EV_FETCH_COMPLETED = 'evFetchCompleted';
export const EV_ERROR = 'evError';
export const EV_STATUS_UPDATE = 'evStatusUpdate';


const subscribers = {};
let subsId = 0;
let addresses = {}; // ref count
let groups = {}; // ref count
let queue = []; // actual actions queue
let dbsync = 0;
let isSyncing = false;
let fetchConfigCallback;
let queueFetch = false;

export const subscribeEvent = callback => {
  subsId++;
  subscribers[subsId] = callback;    
  if (dbsync === 0){
    dbsync = 1;    
    db.table(DB_UPDATER_QUEUE).toArray().then(items => {
      dbsync = 2;
      queue = items;      
      groups = items.reduce((prev, cur) => {
        if (cur.type === TYPE_GROUP) {
          const id = createGroupId(cur.postData.addressId, cur.postData.groupId, cur.postData.recordId, cur.postData.cvId);
          const data = prev[id];
          const count = data ? data.count + 1 : 1;
          return { ...prev, [id]: { count }};
        }
        return prev;
      }, {});
      addresses = items.reduce((prev, cur) => {
        const id = cur.postData.addressId;
        const data = prev[id];
        const count = data ? data.count + 1 : 1;
        return { ...prev, [id]: { count }};
      }, {});

      callback(createEventTasksCount());      
    });
  } else {
    callback(createEventTasksCount());
  }
  callback(createEventSyncing());
  return subsId;
}

export const unsubscribeEvent = id => {
  delete subscribers[id];
}

const callSubscribers = params => {
  Object.keys(subscribers).forEach(id => subscribers[id](params));
};

const createEventTasksCount = () => {
  return {
    type: EV_TASKS_COUNT,
    data: {
      count: queue.length
    }
  };
};

const createEventSyncing = () => {
  return {
    type: EV_SYNCING,
    data: {
      syncing: isSyncing
    }
  };
};

const createEventError = error => {
  return {
    type: EV_ERROR,
    data: {
      error
    }
  };
};

export const getProcessedAddress = () => addresses;

export const updateStatus = (url, config, postData) => {  
  const id = postData.addressId;  
  const data = addresses[id];
  const count = data ? data.count + 1 : 1;
  addresses = { ...addresses, [id]: { count }}; 

  const task = {
    type: TYPE_STATUS,
    url,
    config,    
    postData: {
      ...postData,
      lat: 0,
      lon: 0
    }
  };
  queue.push(task);
  callSubscribers(createEventTasksCount());

  db.table(DB_ADDRESSES)
    .where({ addressId: postData.addressId })
    .modify({
      status: postData.status,
      isProcessing: true
    })
  .then(() => {
    callSubscribers({type: EV_STATUS_UPDATE});
    db.table(DB_UPDATER_QUEUE).put(task)
  })
  .then(() => {
    getUserLocation().then(pos => {
      task.postData.lat = pos.lat;
      task.postData.lon = pos.lon;
      return db.table(DB_UPDATER_QUEUE)
              .update(task.id, task)
              .then(() => sync());
    })
  })  
}

export const updateGroups = (url, config, postData) => {
  const id = postData.addressId;  
  const data = addresses[id];
  const count = data ? data.count + 1 : 1;
  addresses = { ...addresses, [id]: { count }};

  const groupId = createGroupId(postData.addressId, postData.groupId, postData.recordId, postData.cvId);
  const group = groups[groupId];
  const groupCount = group ? group.count + 1 : 1;
  groups = { ...groups, [groupId]: { count: groupCount }};

  const task = {
    type: TYPE_GROUP,
    url,
    config,    
    postData: {
      ...postData,
      lat: 0,
      lon: 0
    }
  };
  queue.push(task);
  callSubscribers(createEventTasksCount());

  Promise.all([
    db.table(DB_ADDRESSES)
      .where({ addressId: postData.addressId })
      .modify(item => {                          
        item.isProcessing = true;

        const record = item.records.find(r => r.recordId === task.postData.recordId);
        const procGroups = record.processingGroups || {};
        record.processingGroups = { ...procGroups, [task.postData.groupId]: true };        
        
        const groups = record.groups || [];
        if (task.postData.action === 0) {
          record.groups = groups.filter(g => g !== task.postData.groupId);
        } else {
          record.groups = [...groups, task.postData.groupId];
        }
      }),
    db.table(DB_UPDATER_QUEUE).put(task)
  ]).then(() => 
    getUserLocation().then(pos => {
      task.postData.lat = pos.lat;
      task.postData.lon = pos.lon;
      return db.table(DB_UPDATER_QUEUE)
              .update(task.id, task)
              .then(() => sync());
    })
  ); 
};

export const sync = (fetch = false) => {  
  queueFetch = fetch;
  syncInternal(false);
}

export const syncInternal = skipCheck => {
  if (isSyncing && !skipCheck) return;

  if (!isSyncing) {
    isSyncing = true;
    callSubscribers(createEventSyncing());
  }

  if (queue.length > 0) {            
    const task = queue[0];    
    axios
      .post(task.url, task.postData, task.config)
      .then(resp => {        
        if (resp.data.status === 'ok') {                 
          db.table(DB_UPDATER_QUEUE).delete(task.id).then(() => {
            let updateDb = false;

            const id = task.postData.addressId;
            const address = addresses[id];
            const count = address.count - 1;
            
            if (count === 0) {
              updateDb = true;
              const { [id]:_, ...others} = addresses;
              addresses = others;                                
            } else {
              addresses = { ...addresses, [id]: { count } };
            }

            let groupCount = -1;
            if (task.type === TYPE_GROUP) {              
              const groupId = createGroupId(
                task.postData.addressId,
                task.postData.groupId,
                task.postData.recordId,
                task.postData.cvId,
              );
              const group = groups[groupId];
              groupCount = group.count - 1;              

              if (groupCount === 0) {
                updateDb = true;
                const { [groupId]:_, ...others } = groups;
                groups = others; 
              } else {
                groups = { ...groups, [groupId]: { count: groupCount } };
              }
            }            

            if (updateDb) {              
              db.table(DB_ADDRESSES)
                .where({ addressId: id })
                .modify(item => {        
                  if (count === 0) {
                    item.isProcessing = false;
                  }                  
                  if (groupCount === 0) {
                    const record = item.records.find(r => r.recordId === task.postData.recordId);
                    const { [task.postData.groupId]:_, ...others } = record.processingGroups || {};
                    record.processingGroups = others;
                  }
                })
                .then(() => {
                  const updateProcessing = count === 0 ? true : null;
                  const updateProcessingGroups = groupCount === 0 ? {
                    recordId: task.postData.recordId,
                    cvId: task.postData.cvId,
                    groupId: task.postData.groupId,
                  } : null;
                  callSubscribers({
                    type: EV_ADDRESS_PROCESSED,
                    data: {
                      id,
                      updateProcessing,
                      updateProcessingGroups
                    }
                  });
                })
                .catch(err => console.log('Error:', err))
            }
            
            queue.splice(0, 1);         
            callSubscribers(createEventTasksCount());   
            syncInternal(true);
          });
        } else {          
          isSyncing = false;
          callSubscribers(createEventSyncing());
          callSubscribers(createEventError('Error: invalid response'));
        }        
      })
      .catch(err => {
        isSyncing = false;
        callSubscribers(createEventSyncing());    
        if (err.response) {
          callSubscribers(createEventError(`${err}`));
        }
      });
  } else if (queueFetch) {
    queueFetch = false;
    fetch().then(() => {
      syncInternal(true);
    });    
  } else {
    isSyncing = false;
    callSubscribers(createEventSyncing());
  }
};

export const setFetchPacketDataConfigCallback = (callback) => {  
  fetchConfigCallback = callback;
};

export const fetch = () => {
  if (!fetchConfigCallback) return Promise.resolve();

  callSubscribers({
    type: EV_FETCH_START
  });
  
  return axios(fetchConfigCallback())
    .then(response => {
      callSubscribers({
        type: EV_FETCH_COMPLETED,
        data: response.data
      });
    })
    .catch(err => {
      callSubscribers({
        type: EV_FETCH_COMPLETED,
        data: {
          error: err
        }
      });
    });
};

const getUserLocation = () => {
  return new Promise((resolve) => {    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {          
          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude
          });
        },
        () => {          
          resolve({ lat: 0, lon: 0});
        },
        {
          enableHighAccuracy: true,
          timeout: 3000,
          maximumAge: 30000
        }
      );
    } else {
      resolve({ lat: 0, lon: 0});
    }
  });
};

export const createGroupId = (addressId, groupId, recordId, cvId) => {
  return `${addressId}-${groupId}-${recordId}-${cvId}`;
};