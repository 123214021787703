import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Container from "@material-ui/core/Container";
import PacketHeader from "../PacketHeader/PacketHeader";
import StreetItem from "../StreetItem/StreetItem";
import { getAppBarHeight } from "../../../utils.js";
import useShowError from "../../../hooks/useShowError.js";
import * as updater from "../../../updater.js";

const useStyles = makeStyles(theme => ({
  list: {
    padding: theme.spacing(0, 0, 1)
  },
  menuItemIcon: {
    minWidth: "36px"
  },
  progressColor: {}
}));

const StreetListing = props => {
  const classes = useStyles();
  const [appBarHeight, setAppBarHeight] = useState(
    getAppBarHeight(window.innerWidth)
  );

  useEffect(() => {
    const handleResize = () => {
      setAppBarHeight(getAppBarHeight(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [errorComponent, setErrorMsg] = useShowError();
  
  useEffect(() => {
    const eventId = updater.subscribeEvent(event => {
      switch(event.type) {
        case updater.EV_ERROR:
          setErrorMsg(event.data.error);
          break;
        default:
      }
    });

    return () => {      
      updater.unsubscribeEvent(eventId);
    };  
  }, [setErrorMsg])

  return (
    <Container maxWidth="sm">
      <PacketHeader setErrorMsg={setErrorMsg} {...props} />
      <span
        className="icon-vote"
        style={{
          color: "rgba(0,0,0,0)",
          position: "absolute",
          left: 0,
          top: -30
        }}
      >
        &nbsp;
      </span>
      <List
        component="div"
        aria-label="streets list"
        id="streets-list"
        className={classes.list}
        style={{
          height: `${window.innerHeight - 200 - appBarHeight}px`,
          overflow: "auto"
        }}
      >
        {props.streetNames.map(street => {
          if (
            props.formData.showWalkedAddresses === false &&
            street.hidden === street.total
          ) {
            return null;
          }
          return (
            <StreetItem
              street={street}
              key={street.name}
              selectStreet={props.selectStreet}
            />
          );
        })}
      </List>
      {errorComponent}
    </Container>
  );
};

export default StreetListing;
