export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isAndroid = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf("android") > -1; //&& ua.indexOf("mobile");
};

export const isInStandaloneMode = () =>
  window.matchMedia('(display-mode: standalone)').matches

export const getAppBarHeight = width => {
  return width < 600 ? 56 : 64;
};

export const isCachedMapExists = (packetDetail) => {
  const packetId = localStorage.getItem("cacheMapId");
  return packetId && packetDetail.packetString === packetId;
}

export const getCachedMap = () => {
  return JSON.parse(localStorage.getItem("cacheMap"));
}
