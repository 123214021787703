import React, { useEffect, useState } from "react";
import Transition from "react-transition-group/Transition";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
import PacketListing from "../PacketListing/PacketListing";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MapComponent from "./MapComponent";
import db from "../../../db";

const styles = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tabs: {
    marginBottom: theme.spacing(1.5)
  },
  packetHeader: {
    backgroundColor: "#fff"
  },
  packetHeaderContent: {
    padding: theme.spacing(1.5) + "px !important"
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  packetTitle: {
    flexGrow: 1,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  packetCode: {
    color: "#767676"
  },
  addressContainer: {
    padding: theme.spacing(8, 2, 2),
    backgroundColor: "#e5e5e5",
    height: "100%"
  }
});

const LiveMap = props => {
  const { classes } = props;
  const getAppBarHeight = width => {
    return width < 600 ? 56 : 64;
  };

  const [appBarHeight, setAppBarHeight] = useState(
    getAppBarHeight(window.innerWidth)
  );

  const [mapHeight, setMapHeight] = useState(
    window.innerHeight - appBarHeight + "px"
  );
  const [geoCodes, setGeoCodes] = useState([]);
  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [mapAddress, setMapAddress] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setAppBarHeight(getAppBarHeight(window.innerWidth));
      setMapHeight(
        window.innerHeight - getAppBarHeight(window.innerWidth) + "px"
      );
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    db.table("geoCodes")
      .toArray()
      .then(allGeoCodes => {
        setGeoCodes(allGeoCodes);
      });
  }, [props.isFetching]);

  const selectGeoCode = geoCode => {
    setShowAddressDialog(true);
    setMapAddress(geoCode.ids);
    setSelectedMarker(geoCode.address);
  };

  const markedCompleted = idx => {
    const currGeoCodes = JSON.parse(JSON.stringify(geoCodes));
    const index = currGeoCodes.findIndex(x => x.address === selectedMarker);
    if (index >= 0) {
      let allCompleted = false;
      currGeoCodes[index].ids[idx.toString()] =
        currGeoCodes[index].ids[idx.toString()] === 2 ? 3 : 2;
      if (Object.values(currGeoCodes[index].ids).findIndex(x => x === 2) < 0) {
        allCompleted = true;
      }

      currGeoCodes[index].status = allCompleted ? 3 : 2;
      setGeoCodes(currGeoCodes);
    }
  };

  return (
    <div>
      {geoCodes.length > 0 && (
        <MapComponent
          geoCodes={geoCodes}
          colorId={props.packet.colorId}
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: mapHeight }} />}
          mapElement={<div style={{ height: `100%` }} />}
          selectGeoCode={selectGeoCode}
          isDisconnected={props.isDisconnected}
          showWalkedAddresses={props.showWalkedAddresses}
          syncAndFetch={props.syncAndFetch}
          isSyncing={props.isSyncing}
          syncCount={props.syncCount}
        />
      )}
      <Dialog
        fullScreen
        open={showAddressDialog}
        onClose={() => setShowAddressDialog(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setShowAddressDialog(false)}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Map Location Addresses
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.addressContainer}>
          <PacketListing
            isMap={true}
            markedCompleted={markedCompleted}
            mapAddress={selectedMarker}
            mapIds={mapAddress}
            {...props}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(LiveMap);
