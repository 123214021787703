import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Transition from "react-transition-group/Transition";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import SyncIcon from "@material-ui/icons/Sync";
import MapIcon from "@material-ui/icons/Map";
import ExploreIcon from "@material-ui/icons/Explore";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/styles";
import Badge from "@material-ui/core/Badge";
import { green } from "@material-ui/core/colors";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { getCachedMap, isCachedMapExists } from "../../../utils";
import LiveMap from "../LiveMap/LiveMap";
import PacketListing from "../PacketListing/PacketListing";
import MenuIcon from "@material-ui/icons/Menu";

const styles = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tabs: {
    marginBottom: theme.spacing(1.5)
  },
  packetHeader: {
    backgroundColor: "#fff"
  },
  packetHeaderContent: {
    padding: theme.spacing(1.5) + "px !important"
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  packetTitle: {
    flexGrow: 1,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  packetCode: {
    color: "#767676"
  },
  disconnected: {
    padding: theme.spacing(1, 1),
    backgroundColor: "#ffc107",
    textAlign: "center"
  },
  customBadge: {
    backgroundColor: "#ffc107"
  }
});

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}))(Badge);

const PacketToolbar = props => {
  const { classes, ...other } = props;
  const [showMap, setShowMap] = useState(false);
  const [showLiveMap, setShowLiveMap] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const imageWidth = 1280,
    imageHeight = 960;
  const [isLandscape, setIsLandscape] = useState(true);

  const getAppBarHeight = width => {
    return width < 600 ? 56 : 64;
  };

  const [appBarHeight, setAppBarHeight] = useState(
    getAppBarHeight(window.innerWidth)
  );
  const [innerHeight, setInnerHeight] = useState(
    window.innerHeight - appBarHeight
  );

  let syncIcon = (
    <StyledBadge badgeContent={props.syncCount} color="primary">
      <SyncIcon />
      {props.isSyncing && (
        <CircularProgress size={36} className={classes.fabProgress} />
      )}
    </StyledBadge>
  );

  const handleIconClick = (e, value) => {
    if (value === 0) {
      setShowMap(true);
    } else if (value === 1) {
      setShowLiveMap(true);
    } else if (value === 2) {
      props.syncAndFetch();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
      setAppBarHeight(getAppBarHeight(window.innerWidth));
      setInnerHeight(window.innerHeight - getAppBarHeight(window.innerWidth));

      if (window.innerHeight / window.innerWidth > imageHeight / imageWidth) {
        setIsLandscape(true);
      } else {
        setIsLandscape(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Paper square className={classes.tabs}>
        <Tabs
          value={false}
          onChange={handleIconClick}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon tabs example"
        >
          <Tab
            label={<small>Overview</small>}
            icon={<MapIcon />}
            aria-label="phone"
          />
          <Tab
            label={<small>Live Map</small>}
            icon={<ExploreIcon />}
            aria-label="favorite"
          />
          <Tab
            label={<small>Sync</small>}
            icon={syncIcon}
            aria-label="favorite"
            disabled={props.isDisconnected}
          />
        </Tabs>
      </Paper>
      <Dialog
        fullScreen
        open={
          showMap ||
          showLiveMap ||
          (props.selectedStreet !== null && !props.showChild)
        }
        onClose={() => {
          if (showMap) {
            setShowMap(false);
          } else if (showLiveMap) {
            setShowLiveMap(false);
          } else if (props.selectedStreet) {
            props.selectStreet(null);
          }
        }}
        TransitionComponent={Transition}
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                if (showMap) {
                  setShowMap(false);
                } else if (showLiveMap) {
                  setShowLiveMap(false);
                } else if (props.selectedStreet) {
                  props.selectStreet(null);
                }
              }}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant={showMap ? "body1" : "h6"}
              className={showMap ? "" : classes.packetTitle}
            >
              {showMap
                ? "This map is for reference only when offline and is not an interactive Live Map"
                : localStorage.getItem("campaignName")}
            </Typography>
            {showLiveMap && (
              <IconButton
                onClick={props.toggleDrawer(true)}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <Badge
                  classes={{ badge: classes.customBadge }}
                  variant="dot"
                  invisible={!props.updateAvailable}
                >
                  <MenuIcon />
                </Badge>
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        {props.isDisconnected && (
          <Paper className={classes.disconnected}>
            Internet connection lost
          </Paper>
        )}
        <div
          style={{
            width: `${innerWidth}px`,
            height: `${innerHeight - (props.isDisconnected ? 36 : 0)}px`
          }}
        >
          {showMap && (
            <PinchZoomPan
              initialScale={
                isLandscape
                  ? innerHeight / imageHeight
                  : innerWidth / imageWidth
              }
              maxScale={
                isLandscape
                  ? (innerHeight / imageHeight) * 5
                  : (innerWidth / imageWidth) * 5
              }
              minScale={
                isLandscape
                  ? innerWidth / imageWidth
                  : innerHeight / imageHeight
              }
            >
              <img
                src={
                  isCachedMapExists(props.packet)
                    ? getCachedMap()
                    : props.packet.urlMap
                }
                alt="Overview Map"
              />
            </PinchZoomPan>
          )}
          {showLiveMap && <LiveMap {...other} />}
          {props.selectedStreet !== null && !props.showChild && (
            <PacketListing {...other} />
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(PacketToolbar);
