import Dexie from "dexie";
import { DB_UPDATER_QUEUE } from "./updater";

const db = new Dexie("WevWalkApp");

db.version(4).stores({
  packet: "packetCode",
  addresses: "addressId,packetCode,streetName,address",
  groups: "++,id,packetCode",
  lastPacketCode: "[id+date],packetCode,packetString",
  syncQueue: "++id",  
  geoCodes: "++,address,lat,lon",
  [DB_UPDATER_QUEUE]: "++id",
});

db.version(3).stores({
  packet: "packetCode",
  addresses: "addressId,packetCode,streetName,address",
  groups: "++,id,packetCode",
  lastPacketCode: "[id+date],packetCode,packetString",
  syncQueue: "++id",
  geoCodes: "++,address,lat,lon"
});

db.version(2).stores({
  packet: "packetCode",
  addresses: "addressId,packetCode,streetName",
  groups: "++,id,packetCode",
  lastPacketCode: "[id+date],packetCode,packetString",
  syncQueue: "++id",
  geoCodes: "++,address,lat,lon"
});

db.version(1).stores({
  packet: "packetCode",
  addresses: "addressId,packetCode,streetName",
  groups: "++,id,packetCode",
  lastPacketCode: "[id+date],packetCode,packetString",
  syncQueue: "++id"
});

export default db;
