import React, {useState, useCallback} from "react";
import {makeStyles} from "@material-ui/styles";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  errorSnackbar: {
    backgroundColor: "#B71C1C"
  },
});

const useShowError = () => {
  const classes = useStyles();
  const [msg, setMsg] = useState();
  const closeCallback = useCallback(() => {
    setMsg(null);
  }, [setMsg]);  

  const snackBar = <Snackbar
      anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
      }}
      open={!!msg}
      autoHideDuration={10000}
      onClose={closeCallback}
      ContentProps={{
          classes: {
              root: classes.errorSnackbar
          },
          'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{msg}</span>}
      action={[
          <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={closeCallback}>
              <CloseIcon/>
          </IconButton>,
      ]}
  />;

  return [snackBar, setMsg];
};

export default useShowError;
